<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A gas sample occupies a volume of
        <number-value :value="volume" unit="\text{L}" />
        at
        <chemical-latex content="\text{20.3}^\circ\text{C}" />
        and a pressure of
        <number-value :value="pressure" unit="\text{atm.}" />
      </p>

      <p class="mb-2">a) How many moles of gas are in the sample?</p>

      <calculation-input
        v-model="inputs.inputA"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        b) The sample has a mass of
        <latex-number :number="massGass.toFixed(3)" unit="\text{g.}" />
        Which homonuclear diatomic gas is it? <b>Hint.</b> Determine the molar mass of the gas.
      </p>

      <chemical-notation-input v-model="inputs.inputB" :disabled="!allowEditing" class="mb-0" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'ChemCPP1210S4_Q5',
  components: {
    LatexNumber,
    ChemicalNotationInput,
    ChemicalSymbolInput,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    massGass() {
      let versionNum = this.taskState.getValueBySymbol('version')?.content?.value ?? 1;
      let massGass = '';
      switch (versionNum) {
        case 1: // H2
          massGass =
            (2.016 * this.pressure.toFloat() * this.volume.toFloat()) / 0.08206 / (20.3 + 273.15);
          break;
        case 2: // N2
          massGass =
            (28.014 * this.pressure.toFloat() * this.volume.toFloat()) / 0.08206 / (20.3 + 273.15);
          break;
        case 3: // O2
          massGass =
            (31.999 * this.pressure.toFloat() * this.volume.toFloat()) / 0.08206 / (20.3 + 273.15);
          break;
        case 4: // F2
          massGass =
            (37.997 * this.pressure.toFloat() * this.volume.toFloat()) / 0.08206 / (20.3 + 273.15);
          break;
        case 5: // Cl2
          massGass =
            (70.906 * this.pressure.toFloat() * this.volume.toFloat()) / 0.08206 / (20.3 + 273.15);
          break;
      }
      return massGass;
    },
  },
};
</script>
